
import { Component, Vue, Watch } from "vue-property-decorator";
import gql from "graphql-tag";
import TopAppBar from "@/components/base/TopAppBar.vue";

@Component({
  components: { TopAppBar },
  apollo: {
    providerId: {
      error() {
        this.$notify({
          type: "error",
          text: "Пользователь не найден",
        });
        this.$router.replace("/");
      },
      update(data) {
        return data.userProviderId;
      },
      query: gql`
        query ($_id: ID!) {
          userProviderId(_id: $_id)
        }
      `,
      variables() {
        return { _id: this.$route.params.id };
      },
    },
  },
})
export default class AppSteamRedirect extends Vue {
  private providerId: string | null = null;

  @Watch("providerId", { immediate: true })
  providerIdWatcher(value) {
    if (value) {
      document.location.href = `https://steamcommunity.com/profiles/${value}`;
    }
  }
}
